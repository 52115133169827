import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public layoutsidebar: boolean = false;
  public activeItem: string;

  public menuItems: Menu[];

  constructor(private router: Router, public navServices: NavService, private translate: TranslateService) {
    this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
    translate.setDefaultLang('mk');
    translate.use('mk');
  }

  ngOnInit(): void {
    if(localStorage.getItem("lang")){
      this.useLanguage(localStorage.getItem("lang"))
    }
    
  }

  isActive(menuItem) {
  	return this.activeItem === menuItem
  }

  layoutSidebarToggle() {
  	this.layoutsidebar = !this.layoutsidebar
  }

  opensettingcontent(val) {
  	if (this.activeItem === val) {
      this.activeItem = '';
    } else {
      this.activeItem = val;
    }
  }

  customizeLayoutType(val) {
    if(val == 'rtl') {
      document.body.classList.remove('ltr')
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl')
      document.body.classList.add('ltr')
    }
  }

  customizeThemeColor(event) {
    document.documentElement.style.setProperty('--theme-deafult', event.target.value);
  }
  customizeLayoutDark() {
    document.body.classList.toggle('dark')
  }

  useLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem("lang",language)
}

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
