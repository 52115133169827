<!--footer section -->
<footer [class]="class">

	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-contant">
						<div class="footer-logo">
							<img src="assets/images/icon/logoName.png" alt="logo">
						</div>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/profile.php?id=100069913953298&mibextid=LQQJ4d"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/turametal/?hl=en"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Tura Metal</h4>
                        </div>
                        <div class="footer-contant">
                           <p>
							{{"footerExpl" | translate}}
						   </p>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>{{"quickLinks" | translate}}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul class="contact-list">
                                <li><a routerLink="/home/turametal" fragment="about">{{"navAbout" | translate}}</a></li>
                                <li><a routerLink="/home/turametal" fragment="gallery">{{"navGallery" | translate}}</a></li>
                                <li><a routerLink="/home/turametal" fragment="services" >{{"navServices" | translate}}</a></li>
                                <li><a routerLink="/home/turametal" fragment="contact">{{"navContact" | translate}}</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>{{"footerInfo" | translate}}</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i> <a href="https://goo.gl/maps/Fn2S4b4M8yDWChGP8">Vrapciste, Gostivar</a> </li>
						        <li><i class="fa fa-phone"></i> <a href="tel:+389 72 276 565">{{"footerCall" | translate}}: +389 72 276 565</a></li>
						        <li><i class="fa fa-envelope-o"></i> <a href="mailto:turametal1@gmail.Com">turametal1@gmail.com</a></li>
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                  <div class="text-center">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} <a href="https://storenet.cz/">Storenet</a></p>
                  </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->