<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  
  <!-- <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>Turametal</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: 123 - 456 - 7890</li>
            </ul>
          </div>
        </div>
        <div class="header-contact col-lg-6 text-end">
          <li>Turametal</li>
          <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: 123 - 456 - 7890</li>
        </div>
      </div>
    </div>
  </div> -->
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <div class="brand-logo">
              <a routerLink="/home/gym" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
              
            </div>
            <h3 style="color: aliceblue;">Turametal</h3> 
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu class="mr-4"></app-menu>
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
