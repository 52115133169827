<div class="main-navbar">
  <div id="mainnav">
    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div>
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

      <li class="back-btn">
        <div class="mobile-back text-end" (click)="mainMenuToggle()">
          <span>{{"back" | translate}}</span>
          <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
        </div>
      </li>

      <li>
        <a routerLink="/home/turametal" fragment="about">{{"navAbout" | translate}} </a>
      </li>
      <li>
        <a routerLink="/home/turametal" fragment="gallery">{{"navGallery" | translate}}</a>
      </li>
      <li>
        <a routerLink="/home/turametal" fragment="services">{{"navServices" | translate}}</a>
      </li>
      <li>
        <a routerLink="/home/turametal" fragment="contact">{{"navContact" | translate}}</a>
      </li>
      <li>
        <!-- <app-settings></app-settings> -->
        <div class="dropdown">
          <button class="dropbtn"><i style="font-size: 1.5rem;" class="icofont icofont-world"></i></button>
          <div  class="dropdown-content">
            <a (click)="useLanguage('tr')">Türkçe</a>
            <a (click)="useLanguage('mk')">македонски јазик</a>
            <a (click)="useLanguage('al')">Shqip</a>
            <a (click)="useLanguage('en')">English</a>
          </div>
        </div>
      </li>
      <li>
        <div class="dropdown">
          <button class="dropbtn"> <i class="icofont icofont-adjust" style="font-size: 1.5rem;" (click)="customizeLayoutDark()"></i></button>
        </div>    
      </li>  
    </ul>
  </div>
</div>
